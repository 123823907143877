<template>
  <base-section id="about-our-product">
    <div align="center">
      <img
        :src="require('@/assets/logo.svg')"
        contain
        max-width="52"
        class="mb-3 hidden-xs-only"
        width="52"
      >
    </div>

    <base-section-heading
      color="grey lighten-2"
      title="What we offer"
    />
    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          title: 'For Clients',
          subtitle: 'Pool of resources available on demand',
          text: 'Use the ScrumOnDemand platform to post projects and be quickly matched to talent based on your project needs. Resource pools are available from local and global markets. ScrumOnDemand enables quick onboarding and payment processing with your talent choices.',
          callout: '01',
        },
        {
          title: 'For Talent/Teams',
          subtitle: 'Project job opportunities',
          text: 'Use the ScrumOnDemand platform to join as an individual and find projects; or form a team with other talent and apply for projects that match your skill sets. ScrumOnDemand will help match you with Clients, set up the engagements, finalize contracts, and facilitate payments. ScrumOnDemand also allows you or your team to build reputations for delivering quality work for repeat business.',
          callout: '02',
        },
        {
          title: 'For Clients & Talents/Teams',
          subtitle: 'Successful project fulfilment',
          text: 'Let ScrumOnDemand accomplish project resource fulfillment. For Clients – find talent or teams to meet your project and development needs. For Talent – offer your skills, learn new skills, and meet other talent to form highly efficient Scrum teams ready to provide expertise to a Client project.',
          callout: '03',
        },
      ],
    }),
  }
</script>
